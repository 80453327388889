import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import 'rxjs/add/operator/map';
import { APICall, APIParams, IpRequest, IpRequests } from './IpRequest';
import { environment } from './../../../../environments/environment';

@Injectable()
export class PendingRequestsService {
    private basePathUrl = environment.basePathUrl;
    private apiName = 'netAPI'; 
    private getPendingRequestsByManagerIdURL = 'pendingRequestsByManagerId';
    private getPendingRequestsByEquipmentIdURL = 'pendingRequestsByEquipmentId';
    private cancelRequestByIdURL = 'cancelRequestById';    
    private cancelPendingRequestsByInterfaceURL = 'cancelPendingRequestsByInterface';    
    private confirmRequestByIdURL = 'confirmRequestById';    
    
    private currentUserName = Cookie.get('iuser');
    private currentUserToken = Cookie.get('itoken');
    private currentUserIdRH = Cookie.get('iidRH');
    constructor(private http: Http) { }
    
    getPendingRequestsByManagerId(idRH: number) {
        let headers = new Headers();
		headers.append('Content-Type', 'application/json;charset=utf-8');

        let params: APIParams = new APIParams();
        params.idRH = idRH;

        let apiCall: APICall = new APICall();
        apiCall.httpMethod = 'GET';
        apiCall.apiName = this.apiName;
        apiCall.apiURI = this.getPendingRequestsByManagerIdURL;
        apiCall.params = params;
        apiCall.username = this.currentUserName;
        apiCall.userToken = this.currentUserToken;

        let body = JSON.stringify(apiCall);
        let options = new RequestOptions({ headers: headers });

        return this.http
            .post(this.basePathUrl, body, options)
            .map(this.extractRequestsData)
    }

    getPendingRequestsByEquipmentId(idEquip: string){
        let headers = new Headers();
		headers.append('Content-Type', 'application/json;charset=utf-8');

        let params: APIParams = new APIParams();
        params.idEquip = idEquip;

        let apiCall: APICall = new APICall();
        apiCall.httpMethod = 'GET';
        apiCall.apiName = this.apiName;
        apiCall.apiURI = this.getPendingRequestsByEquipmentIdURL;
        apiCall.params = params;
        apiCall.username = this.currentUserName;
        apiCall.userToken = this.currentUserToken;

        let body = JSON.stringify(apiCall);
        let options = new RequestOptions({ headers: headers });

        return this.http
            .post(this.basePathUrl, body, options)
            .map(this.extractRequestsData)
    }

    confirmRequestById(idRequest: number) {
        let headers = new Headers();
		headers.append('Content-Type', 'application/json;charset=utf-8');

        let params: APIParams = new APIParams();
        params.idPedido = idRequest;

        let apiCall: APICall = new APICall();
        apiCall.httpMethod = 'POST';
        apiCall.apiName = this.apiName;
        apiCall.apiURI = this.confirmRequestByIdURL;
        apiCall.params = params;
        apiCall.username = this.currentUserName;
        apiCall.userToken = this.currentUserToken;

        let body = JSON.stringify(apiCall);
        let options = new RequestOptions({ headers: headers });

        return this.http
            .post(this.basePathUrl, body, options)
    }

    cancelRequestById(idRequest: number, idInterfacesEquip: number) {
        let headers = new Headers();
		headers.append('Content-Type', 'application/json;charset=utf-8');

        let params: APIParams = new APIParams();
        params.idPedido = idRequest;
        params.idInterfacesEquip = idInterfacesEquip;
        params.modifyUser = this.currentUserIdRH;

        let apiCall: APICall = new APICall();
        apiCall.httpMethod = 'POST';
        apiCall.apiName = this.apiName;
        apiCall.apiURI = this.cancelRequestByIdURL;
        apiCall.params = params;
        apiCall.username = this.currentUserName;
        apiCall.userToken = this.currentUserToken;

        let body = JSON.stringify(apiCall);
        let options = new RequestOptions({ headers: headers });

        return this.http
            .post(this.basePathUrl, body, options)
    }

    cancelPendingRequestsByInterface(idInterfacesEquip: number) {
        let headers = new Headers();
		headers.append('Content-Type', 'application/json;charset=utf-8');

        let params: APIParams = new APIParams();
        params.idInterfacesEquip = idInterfacesEquip;
        params.modifyUser = this.currentUserIdRH;

        let apiCall: APICall = new APICall();
        apiCall.httpMethod = 'POST';
        apiCall.apiName = this.apiName;
        apiCall.apiURI = this.cancelPendingRequestsByInterfaceURL;
        apiCall.params = params;
        apiCall.username = this.currentUserName;
        apiCall.userToken = this.currentUserToken;

        let body = JSON.stringify(apiCall);
        let options = new RequestOptions({ headers: headers });

        return this.http
            .post(this.basePathUrl, body, options)
    }

    extractRequestsData(res: Response) {
        let requests : IpRequests = new IpRequests;
        let body = res.json(); 
        
        for (var i = 0; i < body.length; i++) {
            let request = new IpRequest;
            request.idRequest = body[i].idPedido;
            request.manager = body[i].responsavel;
            request.idInterfacesEquip = body[i].idInterfacesEquip;
            request.idState = body[i].idEstado;
            request.createUser = body[i].createUser;
            request.createDate = body[i].createDate.split(" ")[0];
            request.modifyUser = body[i].modifyUser;
            request.modifyDate = body[i].modifyDate.split(" ")[0];
            
            requests.dataReq[i] = request;
        }  

        return requests
    }
}    