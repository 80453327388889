import {Injectable} from '@angular/core';
import {Http, Response, Headers, URLSearchParams, RequestOptions} from '@angular/http';
import { environment } from './../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {Cookie} from 'ng2-cookies/ng2-cookies';
import {
  InvestmentSupport,
  APICall,
  APIParams
} from './subvInvm';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';

@Injectable()
export class SubvInvmService {
  private basePathUrl = environment.basePathUrl;
  private investmentSupportsUrl = 'getAllInvestmentSupports';
  private activeInvestmentSupportsUrl = 'getActiveInvestmentSupports';
  private createInvestmentSupportUrl = 'insertInvestmentSupport';
  private updateInvestmentSupportUrl = 'updateInvestmentSupport';
  private deleteInvestmentSupportUrl = 'deleteInvestmentSupport';
  private currentUserName = Cookie.get('iuser');//JSON.parse(sessionStorage.getItem('currentUser')).username;
  private currentUserToken = Cookie.get('itoken');//JSON.parse(sessionStorage.getItem('currentUser')).token;
  private alerts: Array<Object> = [];
  private apiName = 'projetoAPI';

  constructor(private http: Http) {
  }

  pushAlert(alert: Object): void {
    this.alerts.push(alert);
  }

  popAlerts(): Array<Object> {
    const temp = this.alerts;
    this.alerts = [];
    return temp;
  }

  getInvestmentSupports(): Observable<InvestmentSupport[]> {
    const apiCall: APICall = new APICall();
    const params: APIParams = new APIParams();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    apiCall.httpMethod = 'GET';
    apiCall.apiName = this.apiName;
    apiCall.apiURI = this.investmentSupportsUrl;
    apiCall.params = params;
    apiCall.username = this.currentUserName;
    apiCall.userToken = this.currentUserToken;
    const body = JSON.stringify(apiCall);
    const options = new RequestOptions({headers: headers});
    return this.http
      .post(this.basePathUrl, body, options)
      .map(this.extractInvestmentSupportData)
      .catch(this.handleError);
  }

  getActiveInvestmentSupports(): Observable<InvestmentSupport[]> {
    const apiCall: APICall = new APICall();
    const params: APIParams = new APIParams();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    apiCall.httpMethod = 'GET';
    apiCall.apiName = this.apiName;
    apiCall.apiURI = this.activeInvestmentSupportsUrl;
    apiCall.params = params;
    apiCall.username = this.currentUserName;
    apiCall.userToken = this.currentUserToken;
    const body = JSON.stringify(apiCall);
    const options = new RequestOptions({headers: headers});
    return this.http
      .post(this.basePathUrl, body, options)
      .map(this.extractInvestmentSupportData)
      .catch(this.handleError);
  }

  createInvestmentSupport(investmentSupports: InvestmentSupport[]): Observable<boolean> {
    const apiCall: APICall = new APICall();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    apiCall.httpMethod = 'POST';
    apiCall.apiName = this.apiName;
    apiCall.apiURI = this.createInvestmentSupportUrl;
    apiCall.body = investmentSupports;
    apiCall.username = this.currentUserName;
    apiCall.userToken = this.currentUserToken;
    const body = JSON.stringify(apiCall);
    const options = new RequestOptions({headers: headers});
    return this.http
      .post(this.basePathUrl, body, options)
      .map(this.extractCreateData)
      .catch(this.handleError);
  }

  updateInvestmentSupport(investmentSupports: InvestmentSupport[]): Observable<boolean> {
    const apiCall: APICall = new APICall();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    apiCall.httpMethod = 'PUT';
    apiCall.apiName = this.apiName;
    apiCall.apiURI = this.updateInvestmentSupportUrl;
    apiCall.body = investmentSupports;
    apiCall.username = this.currentUserName;
    apiCall.userToken = this.currentUserToken;
    const body = JSON.stringify(apiCall);
    const options = new RequestOptions({headers: headers});
    return this.http
      .post(this.basePathUrl, body, options)
      .map(this.extractUpdateData)
      .catch(this.handleError);
  }

  deleteInvestmentSupport(investmentSupport: InvestmentSupport): Observable<boolean> {
    const apiCall: APICall = new APICall();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    apiCall.httpMethod = 'DELETE';
    apiCall.apiName = this.apiName;
    apiCall.apiURI = this.deleteInvestmentSupportUrl;
    apiCall.body = investmentSupport.investmentSupportId;
    apiCall.username = this.currentUserName;
    apiCall.userToken = this.currentUserToken;
    const body = JSON.stringify(apiCall);
    const options = new RequestOptions({headers: headers});
    return this.http
      .post(this.basePathUrl, body, options)
      .map(this.extractDeleteData)
      .catch(this.handleError);
  }

  // Extract
  private extractInvestmentSupportData(res: Response) {
    const body = res.json();
    const investmentSupports: InvestmentSupport[] = new Array<InvestmentSupport>();
    for (let i = 0; i < body.length; i++) {
      const investmentSupport = new InvestmentSupport;
      investmentSupport.investmentSupportId = body[i].investmentSupportId;
      investmentSupport.code = body[i].code;
      investmentSupport.normalFinancingRate = body[i].normalFinancingRate;
      investmentSupport.descriptionEN = body[i].descriptionEN;
      investmentSupport.descriptionPT = body[i].descriptionPT;
      investmentSupport.valBegin = body[i].valBegin;
      investmentSupport.valEnd = body[i].valEnd;
      investmentSupport.createUser = body[i].createUser;
      investmentSupport.modifyUser = body[i].modifyUser;
      investmentSupport.createDate = body[i].createDate;
      investmentSupport.modifyDate = body[i].modifyDate;
      investmentSupports[i] = investmentSupport;
    }
    return investmentSupports;
  }

  private extractCreateData(res: Response) {
    let result = false;
    if (res !== null) {
      const body = res.json();
      if (body !== null) {
        result = body;
      }
    }
    return result;
  }

  private extractUpdateData(res: Response) {
    let result = false;
    if (res !== null) {
      const body = res.json();
      if (body !== null) {
        result = body;
      }
    }
    return result;
  }

  private extractDeleteData(res: Response) {
    let result = false;
    if (res !== null) {
      const body = res.json();
      if (body !== null) {
        result = body;
      }
    }
    return result;
  }

  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.err || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    console.error(errMsg);
    return Observable.throw(errMsg);
  }
}
