import { Injectable } from '@angular/core';
import { Http, Response, Headers, URLSearchParams, RequestOptions } from '@angular/http';
import {Cookie} from 'ng2-cookies/ng2-cookies';
import { Observable } from 'rxjs/Observable';
import { environment } from './../../../environments/environment';

import {

  APICall,
  APIParams,
  TiposEstrutura
} from './estrutura';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';

@Injectable()
export class EstruturaService {
  private basePathUrl =  environment.basePathUrl;
  private basePathValidateComponentUrl =  environment.basePathValidateComponentUrl;
  private basePathValidateSimpleComponentUrl = environment.basePathValidateSimpleComponentUrl;
  private basePathUrlExportByObjectBody = environment.basePathUrlExportByObjectBody;
  private basePathUrlExportByObject = environment.basePathUrlExportByObject;
  private currentUserName = Cookie.get('iuser');//JSON.parse(sessionStorage.getItem('currentUser')).username;
  private currentUserToken = Cookie.get('itoken');//JSON.parse(sessionStorage.getItem('currentUser')).token;
  private alerts: Array<Object> = [];
  private apiName = 'generalAPI';
  private getTiposEstruturaUrl = 'getTiposEstrutura';
  private getTiposEstruturaByIdUrl = 'getTiposEstruturaById';
  private updateTipoEstruturaUrl = 'updateTipoEstrutura';

  constructor(private http: Http) {}

  pushAlert(alert: Object): void {
    this.alerts.push(alert);
  }

  popAlerts(): Array<Object> {
    var temp = this.alerts;
    this.alerts = [];
    return temp;
  }


  getTiposEstruturaById(idTipoEstrutura : number){

    const apiCall: APICall = new APICall();
    const headers = new Headers();
    const params: APIParams = new APIParams();
    params.idTipoEstrutura = idTipoEstrutura;
    headers.append('Content-Type', 'application/json;charset=utf-8');
    apiCall.httpMethod = 'GET';
    apiCall.apiName = this.apiName;
    apiCall.apiURI = this.getTiposEstruturaByIdUrl;
    apiCall.params = params;
   
    apiCall.username = this.currentUserName;
    apiCall.userToken = this.currentUserToken;
    const body = JSON.stringify(apiCall);
    const options = new RequestOptions({ headers: headers });
    return this.http
      .post(this.basePathUrl, body, options)
      .map(this.extractDataSimple)
      .catch(this.handleError);
  }

  getTiposEstrutura(){

    const apiCall: APICall = new APICall();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    apiCall.httpMethod = 'GET';
    apiCall.apiName = this.apiName;
    apiCall.apiURI = this.getTiposEstruturaUrl;
   
    apiCall.username = this.currentUserName;
    apiCall.userToken = this.currentUserToken;
    const body = JSON.stringify(apiCall);
    const options = new RequestOptions({ headers: headers });
    return this.http
      .post(this.basePathUrl, body, options)
      .map(this.extractData)
      .catch(this.handleError);
  }


  updateTiposEstruturas(tipoEstrutura : any){
    const apiCall: APICall = new APICall();
    const headers = new Headers();
    headers.append('Content-Type', 'application/json;charset=utf-8');
    apiCall.httpMethod = 'POST';
    apiCall.apiName = this.apiName;
    apiCall.apiURI = this.updateTipoEstruturaUrl;
    apiCall.body = tipoEstrutura;
    apiCall.username = this.currentUserName;
    apiCall.userToken = this.currentUserToken;
    const body = JSON.stringify(apiCall);
    const options = new RequestOptions({ headers: headers });
    console.log("vai chamar");
    console.log(body);
    return this.http
      .post(this.basePathUrl, body, options)
      .map(this.extractUpdateData)
      .catch(this.handleError);

  }

  private extractDataSimple(res: Response) {
    const desconto = new TiposEstrutura();
    if (res !== null) {
      const body = res.json();
      if (body !== null) {
          
          desconto.idTipoEstrutura = body.idTipoEstrutura;
          desconto.sigla = body.sigla;
          desconto.nome = body.nome;
          desconto.exploreOurStructure = body.exploreOurStructure;
          desconto.ordem = body.ordem;
          desconto.pai = body.pai;
          desconto.nomeEN = body.nomeEN;


        
      }
    }
    return desconto;
  }


  private extractData(res: Response) {
    const tipoEstrutura: TiposEstrutura[] = [];
    if (res !== null) {
      const body = res.json();
      if (body !== null) {
        for (let i = 0; i < body.length; i++) {
          const desconto = new TiposEstrutura();
          desconto.idTipoEstrutura = body[i].idTipoEstrutura;
          desconto.sigla = body[i].sigla;
          desconto.nome = body[i].nome;
          desconto.exploreOurStructure = body[i].exploreOurStructure;
          desconto.pai = body[i].pai;
          desconto.nomeEN = body[i].nomeEN;

          tipoEstrutura.push(desconto);
        }
      }
    }
    return tipoEstrutura;
  }

  private extractUpdateData(res: Response) {
    let result: boolean = false;
    if (res !== null) {
      let body = res.json();
      if (body !== null) {
        result = body;
      }
    }
    return result;
  }

  private extractDeleteData(res: Response) {
    let result: boolean = false;
    if (res !== null) {
      let body = res.json();
      if (body !== null) {
        result = body;
      }
    }
    return result;
  }

  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.err || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    console.error(errMsg);
    return Observable.throw(errMsg);
  }
}
