export class TiposEstrutura {
  idTipoEstrutura: number;
  sigla   : string;
  nome      : string;
  ordem    : number;
  pai      : number;
  nomeEN        : string;
  exploreOurStructure    : string;
  username: string;
  
}


export class APICall {
    httpMethod:string;
    apiName:string;
    apiURI:string;
    params:APIParams;
    body:any;
    username:string;
    userToken:string;
}

export class APIParams {
    idRH:number;
    idTipoEstrutura:number;
}
