import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { APICall, APIParams, Equipment, VM } from '../equipment';
import { environment } from './../../../../environments/environment';

@Injectable()
export class EditEquipmentService {
    private basePathUrl = environment.basePathUrl;
    private apiName = 'netAPI';
    private extendEquipmentExpirationURL = 'extendEquipmentExpiration';
    private updateEquipmentURL = 'updateEquipment';
    private updateVmURL = 'updateVm';
    private archiveEquipmentURL = 'archiveEquipment';
    private terminateHostConnectionsURL = 'terminateHostConnections';
    private createConnectionVmHostURL = 'createConnectionVmHost';
    private terminateVmConnectionsURL = 'terminateVmConnections';

    private currentUserName = Cookie.get('iuser');
    private currentUserIdRH = Cookie.get('iidRH');
    private currentUserToken = Cookie.get('itoken');
    constructor(private http: Http) { }

    extendEquipmentExpiration(currentEquipment: Equipment, extensionDate: number, idEquip: string) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json;charset=utf-8');

        const newExpirationDate = new Date(currentEquipment.expirationDate);
        newExpirationDate.setMonth(newExpirationDate.getMonth() + extensionDate)

        let params: APIParams = new APIParams();
        params.idEquip = idEquip;        
        params.newExpirationDate = newExpirationDate;        
        params.user = this.currentUserIdRH;

        let apiCall: APICall = new APICall();
        apiCall.httpMethod = 'POST';
        apiCall.apiName = this.apiName;
        apiCall.apiURI = this.extendEquipmentExpirationURL;
        apiCall.params = params;
        apiCall.username = this.currentUserName;
        apiCall.userToken = this.currentUserToken;

        let body = JSON.stringify(apiCall);
        let options = new RequestOptions({ headers: headers });

        return this.http
            .post(this.basePathUrl, body, options);
    }

    updateEquipment(newEquipment: Equipment) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json;charset=utf-8');

        let params: APIParams = new APIParams();
        let bodyParams: APIParams = new APIParams();

        bodyParams.idEquip = newEquipment.idEquip;
        bodyParams.numSerie = newEquipment.serialNum;
        bodyParams.oi = newEquipment.project;
        bodyParams.estrutura = newEquipment.structure;
        bodyParams.dataRegisto = newEquipment.registerDate;
        bodyParams.dataValidade = newEquipment.expirationDate;
        bodyParams.idSistemaOperativo = newEquipment.operatingSys;
        bodyParams.createUser = newEquipment.createUser;
        bodyParams.createDate = newEquipment.createDate;
        bodyParams.modifyUser = newEquipment.modifyUser;
        bodyParams.modifyDate = newEquipment.modifyDate;
        bodyParams.idTipoEquip = newEquipment.idTypeEquip;
        bodyParams.responsavel = newEquipment.manager;
        bodyParams.idSala = newEquipment.idRoom;
        bodyParams.idVm = newEquipment.idVm;
        params.user = this.currentUserIdRH;

        let apiCall: APICall = new APICall();
        apiCall.httpMethod = 'POST';
        apiCall.apiName = this.apiName;
        apiCall.apiURI = this.updateEquipmentURL;
        apiCall.body = bodyParams;
        apiCall.params = params;
        apiCall.username = this.currentUserName;
        apiCall.userToken = this.currentUserToken;

        let body = JSON.stringify(apiCall);
        let options = new RequestOptions({ headers: headers });

        return this.http
            .post(this.basePathUrl, body, options);
    }

    updateVm(updatedVm: VM) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json;charset=utf-8');

        let params: APIParams = new APIParams();
        let bodyParams: APIParams = new APIParams();

        bodyParams.idVm = updatedVm.idVm;
        bodyParams.vmDisco = updatedVm.vmDisk;
        bodyParams.vmRam = updatedVm.vmRam;
        bodyParams.vmCPU = updatedVm.vmCPU;
        params.user = this.currentUserIdRH;


        let apiCall: APICall = new APICall();
        apiCall.httpMethod = 'POST';
        apiCall.apiName = this.apiName;
        apiCall.apiURI = this.updateVmURL;
        apiCall.body = bodyParams;
        apiCall.params = params;
        apiCall.username = this.currentUserName;
        apiCall.userToken = this.currentUserToken;

        let body = JSON.stringify(apiCall);
        let options = new RequestOptions({ headers: headers });

        return this.http
            .post(this.basePathUrl, body, options);
    }

    archiveEquipment(idEquip: string) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json;charset=utf-8');

        let params: APIParams = new APIParams();
        params.idEquip = idEquip;
        params.user = this.currentUserIdRH;
                
        let apiCall: APICall = new APICall();
        apiCall.httpMethod = 'POST';
        apiCall.apiName = this.apiName;
        apiCall.apiURI = this.archiveEquipmentURL;
        apiCall.params = params;
        apiCall.username = this.currentUserName;
        apiCall.userToken = this.currentUserToken;

        let body = JSON.stringify(apiCall);
        let options = new RequestOptions({ headers: headers });

        return this.http
            .post(this.basePathUrl, body, options);
    }

    createConnectionVmHost(idVm: string, vmHostId: string) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json;charset=utf-8');

        let params: APIParams = new APIParams();
        params.idVm = idVm;
        params.vmHostId = vmHostId;
        params.user = this.currentUserIdRH;

        let apiCall: APICall = new APICall();
        apiCall.httpMethod = 'POST';
        apiCall.apiName = this.apiName;
        apiCall.apiURI = this.createConnectionVmHostURL;
        apiCall.params = params;
        apiCall.username = this.currentUserName;
        apiCall.userToken = this.currentUserToken;

        let body = JSON.stringify(apiCall);
        let options = new RequestOptions({ headers: headers });

        return this.http
            .post(this.basePathUrl, body, options);
    }

    terminateHostConnections(idEquip: string) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json;charset=utf-8');

        let params: APIParams = new APIParams();
        params.idEquip = idEquip;
        params.user = this.currentUserIdRH;

        let apiCall: APICall = new APICall();
        apiCall.httpMethod = 'POST';
        apiCall.apiName = this.apiName;
        apiCall.apiURI = this.terminateHostConnectionsURL;
        apiCall.params = params;
        apiCall.username = this.currentUserName;
        apiCall.userToken = this.currentUserToken;

        let body = JSON.stringify(apiCall);
        let options = new RequestOptions({ headers: headers });

        return this.http
            .post(this.basePathUrl, body, options);
    }

    terminateVmConnections(idVm: string, idHost: string) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json;charset=utf-8');

        let params: APIParams = new APIParams();
        params.idVm = idVm;
        params.idHost = idHost;
        params.user = this.currentUserIdRH;

        let apiCall: APICall = new APICall();
        apiCall.httpMethod = 'POST';
        apiCall.apiName = this.apiName;
        apiCall.apiURI = this.terminateVmConnectionsURL;
        apiCall.params = params;
        apiCall.username = this.currentUserName;
        apiCall.userToken = this.currentUserToken;

        let body = JSON.stringify(apiCall);
        let options = new RequestOptions({ headers: headers });

        return this.http
            .post(this.basePathUrl, body, options);
    }
}    